import { Button } from "@mui/material";
import React from "react";
import {Dispatch,SetStateAction} from "react"
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled';
import DirectionsBoatFilledOutlinedIcon from '@mui/icons-material/DirectionsBoatFilledOutlined';
import AirplaneTicketOutlinedIcon from '@mui/icons-material/AirplaneTicketOutlined';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import SpokeIcon from '@mui/icons-material/Spoke';
import SpokeOutlinedIcon from '@mui/icons-material/SpokeOutlined';
import PolylineOutlinedIcon from '@mui/icons-material/PolylineOutlined';
import PolylineIcon from '@mui/icons-material/Polyline';
import { DisplayShipLayerType, DisplayDroneLayerType, DisplayDroneSafetyRadiusesLayerType, DrawPolygonLayerType } from "../@types/types";


export const DisplayShipLayer = ({shipLayerVisible,setShipLayerVisible,setIsDistance}:DisplayShipLayerType) => {
    if(shipLayerVisible == false){
      return(<>
    <Button  onClick = {() =>  {setShipLayerVisible(!shipLayerVisible);setIsDistance(false)}} >
      <DirectionsBoatFilledOutlinedIcon/>
    </Button>
    
      </>
      )
    }  
    return(
      <>
      <Button onClick = {() =>  {setShipLayerVisible(!shipLayerVisible);setIsDistance(false)}}>
        <DirectionsBoatFilledIcon/>
      </Button>
      </>

    )
    }
export const DisplayDroneLayer = ({droneLayerVisible,setDroneLayerVisible,setIsDistance} : DisplayDroneLayerType) => {
      if(droneLayerVisible == false){
        return(<>
      <Button onClick = {() =>  {setDroneLayerVisible(!droneLayerVisible);setIsDistance(false)}} >
        <AirplaneTicketOutlinedIcon/>
      </Button>
      
        </>
        )
      }  
      return(
        <>
        <Button onClick = {() =>  {setDroneLayerVisible(!droneLayerVisible);setIsDistance(false)}}>
          <AirplaneTicketIcon/>
        </Button>
        </>

      )
    }
export const DisplayDroneSafetyRadiusesLayer = ({droneSafetyRadiusesVisible,setDroneSafetyRadiusesVisible}:DisplayDroneSafetyRadiusesLayerType) => {
      if(droneSafetyRadiusesVisible == false){
        return ( <>
          <Button onClick = {() =>  {setDroneSafetyRadiusesVisible(!droneSafetyRadiusesVisible);}}>
            <SpokeOutlinedIcon/>
          </Button>
          </>)
      }
      return(
        <>
        <Button onClick = {() =>  {setDroneSafetyRadiusesVisible(!droneSafetyRadiusesVisible);}}>
          <SpokeIcon/>
        </Button>
        </>

      )

    }
export const DrawPolygonLayer = ({isDrawing,setIsDrawing,closePolygon,clearPolygon,clearWaypoints}:DrawPolygonLayerType) => {
      if(isDrawing == false)
        return ( 
        <Button onClick = {() =>  {setIsDrawing(!isDrawing)}} >
      <PolylineOutlinedIcon/>
      </Button>)

      return(
        <>
        <Button onClick = {() =>  {setIsDrawing(!isDrawing)}} >
          <PolylineIcon/>
        </Button>
        <Button onClick = {() => {setIsDrawing(false);closePolygon()}}>
            End Drawing
        </Button>
        <Button onClick = {() => {clearPolygon()}}>
          Clear Drawing
        </Button>
        <Button onClick = {() => {clearWaypoints();}}>
          Clear Waypoints
        </Button>
       </>
      )
    }