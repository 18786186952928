import React, { useState, useEffect, useRef, useMemo } from "react";
import Box from "@mui/material/Box";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify"
import {  Waypoint, GetLinearWayPointsAxiosType, DronetoVesselDistancePlotType} from "../@types/types";
import Map,{Source,Layer} from 'react-map-gl';
import type {LineLayer} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { CircleLayer } from "mapbox-gl";

const API = process.env.REACT_APP_REST_API;


const getLinearWaypoints = async (props:GetLinearWayPointsAxiosType) => {
  return await axios
    .post(
      API + "api/get-waypoints",
      { 

          uid: props.user.uid,
          start: 
          {
            latitude: props.dronePos[0]/1e7,
            longitude:props.dronePos[1]/1e7
          },
          end: 
          {
            latitude: props.vesselPos[0],
            longitude: props.vesselPos[1]
          }
        },
      {
        headers: { Authorization: "JWT " + props.user.token },
      }
    )
   }

const DronetoVesselDistancePlot = ({user,mapRef,isDistance=false,currDrone,currVessel} : DronetoVesselDistancePlotType) => {

    /*
    Steps to fulfill: 
    1. Calculate Haversine Distance (Assuming earth is a perfect sphere)
    2. 
    3. Draw Dotted line to indicate path that drone should take 
    */
    const [waypoints,setWaypoints] = useState <Array<Waypoint>>([])
      // Create a GeoJSON object
  const geojson = {
    type: 'FeatureCollection',
    features:[
      {
    type: 'Feature',
    geometry: {
        type: 'LineString',
        coordinates: waypoints
    },
    properties: {
      name: "He"
    }
  }]
  };
  const lineLayerStyle : LineLayer= {
    id: "lineLayer",
    source: "waypoints-source",
    type: "line",
    paint: {
      'line-color': '#ff0000',
      'line-width': 2
    
    }
  };

const layerStyle : CircleLayer= {
  id: "waypoint-layer",
  source:"waypoints-source",
  type: "circle",
  paint: {
    'circle-radius': 4,
    'circle-color': '#007cbf'
  }
};
      //d = 2R × sin⁻¹(√[sin²((θ₂ - θ₁)/2) + cosθ₁ × cosθ₂ × sin²((φ₂ - φ₁)/2)])
    useEffect(() =>{
        if(waypoints.length !== 0) //To reset
            setWaypoints([])
        if(currDrone == null || currDrone.data == null || currVessel?.latitudeDegrees == null || currVessel?.longitudeDegrees == null)
          return 
        const dronePos : Array<number> = [currDrone.data.latitude,currDrone.data.longitude]
        const vesselPos : Array<number> = [currVessel.latitudeDegrees,currVessel.longitudeDegrees]

        getLinearWaypoints({user,dronePos,vesselPos})
        .then((response) => {
            console.log(response.data.waypoints)
            setWaypoints(response.data.waypoints)
        })
        .catch((err) => console.log(err))

        const mapbox = mapRef?.current?.getMap();
        if (mapRef) {
            mapbox?.flyTo({
              zoom: 9,
              essential: true,
            });
          }
    } ,[isDistance])
   
   if(!isDistance)
        return(
        <>
        </>
        )
    return (
        <Box>
             <Source id="linear-waypoints-source" type="geojson" data={geojson}>
                <Layer {...lineLayerStyle}/>
             </Source> 
        </Box>

    )
}
export default DronetoVesselDistancePlot