import React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { PrivateRoute } from './PrivateRoute'
import Home from '../pages/home/Home'
import Pilot from '../pages/Pilot'
import SignIn from '../pages/SignIn'
import AdminPrivateRoute from './AdminPrivateRoute'
import SignUp from '../pages/SignUp'
import ForgotPassword from '../pages/ForgotPassword'
import Help from '../pages/Help'
import AdminDashboard from '../pages/admin/Dashboard'
import Users from '../pages/admin/Users'
import NotFound from '../pages/NotFound'
import MyProfile from '../pages/MyProfile'
import UserSignUp from '../pages/admin/UserSignUp'
import EditDrone from '../pages/admin/EditDrone'
import FlightHubIntegrated from '../pages/FlightHubIntegrated'
import AISDashboard from '../pages/AISDashboard'
export default function AppNavigation() {
    
  const location = useLocation();
  return (
    <Routes>
    <Route path="/"  key={location.pathname} element={<Navigate to="/home" />} />
    <Route path="/home" key={location.pathname} element={<Home />} />
    <Route
      path="/pilot"
      key={location.pathname}
      element={
        <PrivateRoute>
          <Pilot />
        </PrivateRoute>
      }
    />
    <Route
      path="/flighthubintegrated"
      key={location.pathname}
      element={
        <PrivateRoute>
          <FlightHubIntegrated />
        </PrivateRoute>
      }
    />
    <Route
      path="/AISDashboard"
      key={location.pathname}
      element={
        <PrivateRoute>
          <AISDashboard />
        </PrivateRoute>
      }
    />
     <Route
      path="/profile"
      key={location.pathname}
      element={
        <PrivateRoute>
          <MyProfile />
        </PrivateRoute>
      }
    />
    <Route
      path="/sign-in"
      key={location.pathname}
      element={<SignIn />}
    />
    <Route
      path="/sign-up"
      key={location.pathname}
      element={
      <AdminPrivateRoute>
      <SignUp />
      </AdminPrivateRoute>
    }
    />
     <Route
      path="/user-sign-up"
      key={location.pathname}
      element={
      <AdminPrivateRoute>
      <UserSignUp />
      </AdminPrivateRoute>
    }
    />
    <Route
      path="/edit-drone"
      key={location.pathname}
      element={
      <AdminPrivateRoute>
      <EditDrone />
      </AdminPrivateRoute>
    }
    />
    <Route
      path="/forgot-password"
      key={location.pathname}
      element={<ForgotPassword />}
    />
    <Route
      path="/help"
      key={location.pathname}
      element={
        <PrivateRoute>
          <Help />
        </PrivateRoute>
      }
    />
     <Route
      path="/admin/dashboard"
      key={location.pathname}
      element={
        <AdminPrivateRoute>
          <AdminDashboard />
        </AdminPrivateRoute>
      }
    />
      <Route
      path="/admin/users"
      key={location.pathname}
      element={
        <AdminPrivateRoute>
          <Users />
        </AdminPrivateRoute>
      }
    />
    

    <Route path="*" element={<NotFound />} />
  </Routes>
  )
}
