import { CircleLayer } from "mapbox-gl";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { Layer,Source } from "react-map-gl";
import { VesselPosition } from "../@types/types";

type allVesselPositions={
  vesselPositions : Array<VesselPosition>
}

const DroneRadius = (props:allVesselPositions) => {
if(props == null || props?.vesselPositions.length == 0)
    return (<></>)

const droneCircle : CircleLayer = {
    id: "vessel-safety-radius",
    source:"vessel",
    type: "circle",
    paint: {
      'circle-radius':{
      'stops':[
        [5, 100 / 2445.984], // Approximation for 100m at zoom level 0
        [15, 100 / 2.389]
    ],// Approximation for 100m at zoom level 20
},
      'circle-color': '#FFA500',
      'circle-opacity': 0.5

}
  }
  const geojson = {
    type: 'FeatureCollection',
    features:props.vesselPositions.map((vessel) => ({
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [vessel.longitudeDegrees,vessel.latitudeDegrees]
        },
        properties: {
          name: ""
        }}))
  };

return (
<Source type="geojson" data={geojson}>
    <Layer {...droneCircle}/>
</Source>
)
}

export default DroneRadius