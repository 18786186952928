import axios, { AxiosResponse } from "axios";
import { initializeApp } from "firebase/app";
import {
	getAuth,
	signOut,
  User,
} from "firebase/auth";
import {
	getFirestore,
  doc,
  updateDoc,
} from "firebase/firestore";
import { GridFields, UserGridFields,UserData, LoginState, UserFormData } from "../@types/types";
const {REACT_APP_FIREBASE_API_KEY,REACT_APP_AUTH_DOMAIN,REACT_APP_PROJECT_ID,REACT_APP_STORAGE_BUCKET,REACT_APP_MESSAGING_SENDER_ID,REACT_APP_ID} = process.env;

//REMEMBER TO REMOVE API KEYS!
const firebaseConfig = {

  apiKey:REACT_APP_FIREBASE_API_KEY,

  authDomain: REACT_APP_AUTH_DOMAIN,

  projectId: REACT_APP_PROJECT_ID,

  storageBucket: REACT_APP_STORAGE_BUCKET,

  messagingSenderId: REACT_APP_MESSAGING_SENDER_ID,

  appId: REACT_APP_ID

};

const API = process.env.REACT_APP_REST_API;

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

async function getUserData(token:string,uid:string)
{ 
 return axios.post(API+"api/get-user-data",{
      "uid":uid
  },
  {                                               //Adding header to request
    headers:{
      "Authorization": "JWT " + token
    }
  })
}

async function registerAccount (values : UserFormData,auth : LoginState)
{ //User will send this information to middleware
  let emails : string[] = []
  let passwords : string[]= []
  for(let i =0 ; i < values.users.length; ++i){
      emails.push(values.users[i].email)
      passwords.push(values.users[i].password)
  }
  if(API){
      return axios.post(API +'api/user-sign-up',
      { 
        "emails": emails,
        "passwords": passwords,
        "uid": auth.uid
      },
      {                                               //Adding header to request
        headers:{
          "Authorization": "JWT " + auth.token
        }
      }
      )
  }


};

async function registerDrone (name : string , emails:string[], droneids : string[],token:string,uid:string){
  if(API){
    return axios.post(API +'api/drone-sign-up',
    { 
      "name": name,
      "emails": emails,
      "uid": uid,
      "droneids" : droneids
    },
    {                                               //Adding header to request
      headers:{
        "Authorization": "JWT " + token
      }
  
    }
    )
  }

}

const sendPasswordReset = async (email : string) => {

    return await axios.post(API+'api/reset-password',
    {
      "email":email
    }
    )

  };


const updateUasid = async (uasid : string,droneId : string,isMPA : boolean,token: string) => 
{
  return axios.post(API + "api/uasid",{
    "droneId": droneId,
    "uasid":uasid,
    "isMPA":isMPA
  },{                                               //Adding header to request
    headers:{
      "Authorization": "Bearer " + token
    }
  })

}
const updateDocs = async (data:GridFields,uid:string,token : string)=> 
{
  console.log("Updating : ",data)
  axios.post(API+"api/update-docs",{
    "uid" : uid,
    "data" :data
  },{
    headers:{
      "Authorization" : "JWT " + token
    }
  })

}

const deleteDocs = async(data:GridFields,uid:string,token:string) => 
{
  console.log("Data:",data)
  axios.post(API+"api/delete-docs",{
    "uid" : uid,
    "data": data
  },{
    headers:{
      "Authorization" : "JWT " + token
    }
  })

}

const verifyUserDrone = async (uid:string,token : string,droneId:string)=>
{
  console.log(droneId)
  return axios.post(API+"api/verify-user-drone",{
    "uid" : uid,
    "droneId": droneId
  },{
    headers:{
      "Authorization" : "JWT " + token
    }
  })

}
const deleteUsers = async (uid:string,token: string,userUid : UserGridFields) => 
{
  console.log(userUid)
  axios.post(API+"api/delete-users",{
    "uid" : uid,
    "userUid": userUid,
  },{
    headers:{
      "Authorization" : "JWT " + token
    }
  })
  .then(() => console.log("[+] Successfully Deleted User"))
  .catch((err) => console.log("Error :",err))



}

const getAllUsers = async (uid: string,token: string) => 
 {
  return await axios
      .post(
        API + "api/get-all-users",
        { uid: uid },
        {
          headers: { Authorization: "JWT " + token },
        }
      )
 }
 const getAllDocs = async (user : LoginState) => 
 {
  return await axios
  .post(
    API + "api/get-all-docs",
    { uid: user.uid },
    {
      headers: { Authorization: "JWT " + user.token },
    }
  )

 }
export {
  auth,
  db,
  registerAccount,
  registerDrone,
  sendPasswordReset,
  updateUasid,
  updateDocs,
  deleteDocs,
  verifyUserDrone,
  deleteUsers,
  getUserData,
  getAllUsers,
  getAllDocs
};