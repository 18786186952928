import { Box, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import * as React from 'react';
import {  VesselPosition } from '../@types/types';

function ControlPanel(props : any) {

    const data = props.props
    if(data == null || data == undefined)
        return (<div className="control-panel" style={{position:"fixed",marginLeft:"50vw",marginTop:100, outlineColor:"blue", outlineStyle:"double",backgroundColor:"white" }}>

        </div>)
    else {
    return (
    <Box className="control-panel" style={{
        position: "fixed",
        marginLeft: "1vw", 
        marginTop: "1vh",  
        borderRadius: "8px", 
        border: "1px solid rgba(0, 0, 0, 0.1)", 
        backgroundColor: "rgba(255, 255, 255, 0.9)", 
        backdropFilter: "blur(8px)", 
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", 
        padding: "10px", 
        wordWrap: "break-word",
        whiteSpace: "pre-line",
        fontFamily: "'Roboto', sans-serif", 
        color: "#333", 
    }}>
    <Typography variant="caption">
        vessel name : {data.vesselParticulars.vesselName} <br />
        callsign: {data.vesselParticulars.callSign}<br />
        imoNumber: {data.vesselParticulars.imoNumber}<br />
        flag: {data.vesselParticulars.flag}<br />
        vessel length: {data.vesselParticulars.vesselLength}<br />
        vessel breadth: {data.vesselParticulars.vesselBreadth}<br />
        vesselDepth: {data.vesselParticulars.vesselDepth}<br />
        vesselType: {data.vesselParticulars.vesselType}<br />
        grossTonnage: {data.vesselParticulars.grossTonnage}<br />
        netTonnage: {data.vesselParticulars.netTonnage}<br />
        deadweight: {data.vesselParticulars.deadweight}<br />
        mmsiNumber: {data.vesselParticulars.mmsiNumber}<br />
        yearBuilt: {data.vesselParticulars.yearBuilt}<br />
        latitude (degrees): {data.latitudeDegrees}<br />
        longitude (degrees): {data.longitudeDegrees}<br />
        speed: {data.speed}<br />
        course: {data.course}<br />
        heading: {data.heading}<br />
        timeStamp: {data.timeStamp}<br />
    </Typography>
    </Box>
  );
}
}

export default ControlPanel;