import axios from "axios"
import { LoginState, Waypoint } from "../@types/types";

const API = process.env.REACT_APP_REST_API;

export const getVesselParticulars = async(user: LoginState, vesselName : string,imoNumber : string,callSign : string) =>{
    return await axios
    .post(
      API + "api/get-vessel-particulars",
      { uid: user.uid,
        vesselName: vesselName,
        imoNumber: imoNumber,
        callSign: callSign
       },
      {
        headers: { Authorization: "JWT " + user.token },
      }
    )
   }
export const getAllVesselPositions = async(user: LoginState) => {
    return await axios
    .post(
      API + "api/get-all-vessel-positions",
      { uid: user.uid },
      {
        headers: { Authorization: "JWT " + user.token },
      }
    )
  
}
export const getDronePositions = async(user: LoginState) => {
  return await axios
  .get(
    API + "api/get-drone-positions",
    { params: {uid: user.uid },
      headers: { Authorization: "JWT " + user.token },
    }
  )

}
export const getKMZFile = async(user: LoginState,areapoints : Array<Waypoint>,waypoints : Array<Waypoint> ) => {
  return await axios
  .post(
    API + "api/get-kmz",
    { uid: user.uid,
      areapoints: areapoints,
      waypoints : waypoints
     },
    {
      headers: { Authorization: "JWT " + user.token },
      responseType: 'arraybuffer'
    }
  )

}