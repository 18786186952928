import React, { useState, useEffect } from "react";
import SideBar from "../components/SideBar";
import TextField from "@mui/material/TextField";
import {Button,Checkbox, FormControlLabel,FormGroup, Select,SelectChangeEvent , MenuItem}from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import { updateUasid, verifyUserDrone,  getAllDocs } from "../helpers/Firebase";
import axios, { AxiosResponse } from "axios";
import { useAuthState } from "../hooks/context";
import { CircularProgress, Container, InputLabel } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
const HOST = process.env.REACT_APP_SERVER_IP_ADDR;

const FlightHubIntegrated = () => {
  const [isLoading,setIsLoading] = useState(true);
  const [isAuthorized,setIsAuthorized ] = useState(false)
  const [pageState,setPageState] = useState(<LoadingPage/>)
  const user = useAuthState()
  
  


  useEffect(() => {
        setIsLoading(false)
        getAllDocs(user)
        setPageState(<AuthorizedPage/>)

        }
, [])

return (<Container 
sx={{display:"flex"}}
maxWidth={false}
>
    {pageState}
  </Container>)

}

const AuthorizedPage = () => {
  const [isSwitch, setIsSwitch] = useState<boolean>(false);
  const user = useAuthState()
  const [uasid, setUasid] = useState<string>("");
  const [droneId, setDroneId] = useState <string | undefined>(undefined);
  const [isMPA, setIsMPA] = useState<boolean>(false);
  const label = { inputProps: { "aria-label": "Switch" } };

  const [allDocs,setAllDocs] = useState<Array<string>>([])

  useEffect(() => { 
    getAllDocs(user)
    .then((documents) => {
        let arr = []
        for(let i = 0; i < documents.data.length;++i)
            {
            let droneId = documents.data[i].droneId;
            arr.push(droneId)
            }
        setAllDocs(arr)
    })
    .catch((err) => console.log(err));},[])
 


  const onClick = () => {
        if(droneId != undefined)
        updateUasid(uasid,droneId,isMPA,user.token)
        .then((res : any) => {
            console.log("[+] Successful update to REST")
            toast.success("Successfully updated!")
        })
        .catch((err:Error) => 
            {
            toast.error("Connection Error")
            console.log(err) }
        )
  };

const onSelectedDrone = (event: SelectChangeEvent) =>{
    setDroneId(event.target.value)
}
  return (
    <>
      <SideBar />
      <ToastContainer />
      <Box sx={{ position: "relative", marginLeft: "10em",display: "flex",flex:"row" }}>
        <CssBaseline />
        <div>
          <Typography variant="h3" >
            FlightHub Integrated
          </Typography>
       
            <Typography
              variant="body1"
              display="block"
            >
         Select approved devices allocated to your account, and fill up associated UASID. Click the Submit button and you are done!
          </Typography>
          <FormGroup>
          <InputLabel >Drone S/N</InputLabel>
          <Select 
            label="Drone"
            onChange={onSelectedDrone}
            required
          >

        {allDocs.map((droneId:string, index:number) => (
            <MenuItem 
            key={index} 
            value={droneId}
            >

                {droneId}
            </MenuItem>
      ))}
         </Select>
          <TextField
            id="uasid"
            label="UASID"
            placeholder="6-digit-code"
            variant="outlined"
            required
            onChange={(e) => setUasid(e.target.value)}
            inputProps={{ style: { textTransform: "uppercase" } }}
          />

          <FormControlLabel label= {"Send to MPA"} control={
            <Checkbox
            onChange={(e) => {setIsMPA(!isMPA)}}
            />
            }/>

          <Button
            variant="contained"
            disabled={uasid.length != 6 || droneId == undefined}
            onClick={onClick}
          >
            Submit
          </Button>
          </FormGroup>
        </div>
      </Box>
    </>
  );
      }

const LoadingPage =() => {
  return (
  <>
      <Box style={{display:"flex"}}>
    <SideBar/>
      <div style={{position:"absolute",top:"50%",left:"50%"}}>
      <CircularProgress />
      </div>
    </Box>
  </>)
}



export default FlightHubIntegrated;

