import React, { useState, useEffect, useRef, useMemo, RefObject, useCallback } from "react";
import SideBar from "../components/SideBar";
import TextField from "@mui/material/TextField";
import {Button,Checkbox, FormControlLabel,FormGroup, Select,SelectChangeEvent , MenuItem, Autocomplete}from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import axios, { AxiosResponse } from "axios";
import { useAuthState } from "../hooks/context";
import { CircularProgress, Container, InputLabel } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { LoginState,  VesselParticulars, VesselPosition, PopUpInfo, SearchFieldProps, DronePosition, DronePopUpInfo, Waypoint, PolygonGeoJSON} from "../@types/types";
import Map,{Source, FullscreenControl,Layer, NavigationControl, Popup, Marker, useMap} from 'react-map-gl';
import type {FillLayer,CircleLayer, MapRef} from 'react-map-gl';
import Pin from '../components/Pin';
import 'mapbox-gl/dist/mapbox-gl.css';
import ControlPanel from "./ControlPanel";
import DronetoVesselDistancePlot from "../components/DronetoVesselDistancePlot";
import {  Circle } from "@mui/icons-material";
import DroneRadius from "./DroneRadius";
import { red } from "@mui/material/colors";

import SelectedArea from "../components/SelectedArea";

import { WaypointSelectorControl, DrawAreaSelectorControl } from "../components/DrawingControls";

import { FeatureCollection } from "geojson";
import { getAllVesselPositions, getDronePositions, getKMZFile } from "../helpers/CustomAxiosCalls";
import { DisplayDroneLayer, DisplayDroneSafetyRadiusesLayer, DisplayShipLayer, DrawPolygonLayer } from "../components/DisplayLayers";

const HOST = process.env.REACT_APP_SERVER_IP_ADDR;
const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY


const arrowGeoJSON = (allVesselPositions : Array<VesselPosition>) => {
    //console.log(allVesselPositions)
    return {
    type: 'FeatureCollection',
    features: allVesselPositions.map((item) => ({
      type: 'Feature',
      properties: { id: item.vesselParticulars.imoNumber, rotation: item.heading },
      geometry: {
        type: 'Point',
        coordinates: [item.longitudeDegrees,item.latitudeDegrees],
      },
    })),
}
  };
  //REACT FUNCTION
  /*
  const searchField = (targetVessel : SearchFieldProps, allVesselPositions : Array<VesselPosition>) => {
    const result = allVesselPositions.find(
      (vessel) =>
        vessel.vesselParticulars.vesselName.toLowerCase().includes(targetVessel.vesselName.toLowerCase()) ||
        vessel.vesselParticulars.mmsiNumber.includes(targetVessel.mmsinumber)
    );
    console.log("Searching")
    if (result) {
        const onClick = () => {
            if(map){
            map.flyTo({
            center: [result.longitudeDegrees, result.latitudeDegrees],
            zoom: 20,
            essential: true, // Animation is essential
            });
        }}
        return(
            <Button onClick={onClick}>Go</Button>
        )
    } 
    else {
      alert('Vessel not found');
    }
    return(<div></div>)
  };

*/

const AISDashboard = () => {
  const [isLoading,setIsLoading] = useState(true);
  const [isAuthorized,setIsAuthorized ] = useState(false)
  const [pageState,setPageState] = useState(<LoadingPage/>)
  const user = useAuthState()
  



  useEffect(() => {
        setIsLoading(false)
        setPageState(<AuthorizedPage/>)

        }
, [])

return (
<Container 
sx={{display:"flex"}}
maxWidth={false}
>
    {pageState}
</Container>)
}


const AuthorizedPage = () => {
  const user = useAuthState()
  const [droneId, setDroneId] = useState <string | undefined>(undefined);
  const [VesselParticulars,setVesselParticulars] = useState <VesselParticulars> ()
  const [allVesselPositions,setAllVesselPositions] = useState<Array<VesselPosition>>([])
  const [popupInfo,setPopupInfo] = useState<PopUpInfo | null>(null)
  const [currVessel,setCurrVessel] = useState<VesselPosition | null>(null)
  const [searchInput, setSearchInput] = useState<string>("");
  const [dronePositions,setDronePositions] = useState<Array<DronePosition>>([])
  const [dronePopUpInfo,setDronePopUpInfo] = useState <DronePopUpInfo | null> (null)
  const [currDrone,setCurrDrone] = useState <DronePosition | null> (null)
  const [isDistance,setIsDistance] = useState <boolean> (false)
  const [shipLayerVisible,setShipLayerVisible] = useState<boolean>(false)
  const [droneLayerVisible,setDroneLayerVisible] = useState<boolean>(false)
  const [droneSafetyRadiusesVisible,setDroneSafetyRadiusesVisible] = useState<boolean>(false)
  const [drawArea,setDrawArea] = useState<Array<Waypoint>>([])
  const [isDrawing,setIsDrawing] = useState<boolean>(false)
  const [waypoints,setWaypoints] = useState<Array<Waypoint>>([])
  const [isWaypointSelector,setIsWaypointSelector] = useState<boolean>(false);
  const [isDrawAreaSelector,setIsDrawAreaSelector] = useState<boolean>(false);

  const mapRef = useRef<MapRef>(null);
  useEffect(() => {
        
        getAllVesselPositions(user)
        .then ((response) => {const vessels : Array<VesselPosition> = response.data
        console.log("Retrieving Vessel Data")
        setAllVesselPositions(vessels)
        })
        .catch((err) => {console.log("Error in Retrieving Vessels: ",err)})
    
        getDronePositions(user)
        .then((response) => {const currdronePositions: Array<DronePosition> = Object.values(response.data);
        console.log("Retrieving Drone Position")
        //console.log(currdronePositions)
        setDronePositions(currdronePositions)
        })
      .catch((err) => {console.log("Error in retrieving drone position")}) // Get Drone position every 5 Seconds
},[])


  useEffect(() => {
    const intervalGetVesselPositions = setInterval(async () => {
   try{
        const response = await getAllVesselPositions(user)
        const vessels : Array<VesselPosition> = response.data
        console.log("Retrieving Vessel Data")
        setAllVesselPositions(vessels)
        }
    catch(err){console.log("Error in Retrieving Vessels: ",err)}},120000); //Get Vessel every 3 minutes
    
    const intervalGetDronePositions = setInterval(async () => { 
      try{
      const response = await getDronePositions(user)
        const currdronePositions: Array<DronePosition> = Object.values(response.data);
        console.log("Retrieving Drone Position")
        //console.log(currdronePositions)
        setDronePositions(currdronePositions)
      }
      catch(error){console.log("Error in retrieving drone position")}},3000) // Get Drone position every 5 Seconds
      
      return () => {
        clearInterval(intervalGetVesselPositions);
        clearInterval(intervalGetDronePositions);
      };
},[])

/*
useEffect(() => {
    mapboxgl.accessToken = MAPBOX_API_KEY ? MAPBOX_API_KEY : ""
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
    });

    return () => {
    if(mapRef.current != null)
      mapRef.current.remove()
    }
  }, [])
*/
useEffect(() => {
  setIsDistance(false)
},[currDrone])
useEffect(() => {
    //Re-render Control panel information
    //console.log("Re-rendering")
},[currVessel])
useEffect(() => {
  if(mapRef.current){
  if(isDrawing)
  {
    {mapRef.current.getCanvas().style.cursor = 'crosshair'}
  }
  else
    {mapRef.current.getCanvas().style.cursor = 'pointer'}
  }

},[isDrawing])
//Just refer once, for memoisation FOR NOW
const drones = useMemo(
  () => {
  //console.log("[+] Frontend displaying drone position")
    //console.log(`Stored Drone Positions State ${dronePositions}`)
    //console.log(`${dronePositions.length}`)
    return (dronePositions && dronePositions.length > 0 ? dronePositions.map((drone,index) => {
      return (
      <Marker
          key={`marker-${index}`}
          longitude={drone.data.longitude/10000000}
          latitude={drone.data.latitude/10000000}

          anchor="bottom"
          onClick={e => {
            // If we let the click event propagates to the map, it will immediately close the popup
            // with `closeOnClick: true`

            e.originalEvent.stopPropagation();
            setDronePopUpInfo({
              "UASID": drone.data.UASID,
              "speed": drone.data.speed,
              "verticalSpeed": drone.data.verticalSpeed,
              "trackDirection":drone.data.trackDirection,
              "latitude": drone.data.latitude/10000000,
              "longitude": drone.data.longitude/10000000,
              "pressureAltitude": drone.data.pressureAltitude,
              "geodeticAltitude": drone.data.geodeticAltitude,
              "height": drone.data.height,
              "timestamp": drone.data.timestamp,
              "operatorLatitude": drone.data.operatorLatitude,
              "operatorLongitude": drone.data.operatorLongitude
            });
            setCurrDrone(drone);
          }}
        >
          <Pin heading={drone.data.trackDirection} vesselType="drone"/>
        </Marker>

    )}):[])},[dronePositions])

const pins = useMemo(
    () =>{
        //console.log("ControlPanel re-rendered")
      return (allVesselPositions && allVesselPositions.length > 0 ? allVesselPositions.map((vessel, index) => (
        <Marker
          key={`marker-${index}`}
          longitude={vessel.longitudeDegrees}
          latitude={vessel.latitudeDegrees}
          onClick={e => {
            // If we let the click event propagates to the map, it will immediately close the popup
            // with `closeOnClick: true`
            e.originalEvent.stopPropagation();
            setPopupInfo({
                vesselName: vessel.vesselParticulars.vesselName,
                callSign: vessel.vesselParticulars.callSign,
                mmsiNumber: vessel.vesselParticulars.mmsiNumber,
                longitudeDegrees: vessel.longitudeDegrees,
                latitudeDegrees: vessel.latitudeDegrees,
                speed: vessel.speed,
                heading: vessel.heading
            });
            setCurrVessel(vessel);
          }}
        >
          <Pin heading={vessel.heading} />
        </Marker>
      )) : [])},
    [allVesselPositions]
  );
  const droneSafetyRadiuses = useMemo(
    () =>{
        //console.log("ControlPanel re-rendered")
      return (
          <DroneRadius vesselPositions={allVesselPositions}/>
      )},
    [allVesselPositions]
  );
  const selectedArea = useMemo(() => {
    //console.log(waypoints)
    return (<SelectedArea areaCoords={drawArea} waypointsCoords={waypoints} setWaypointsCoords={setWaypoints} setAreaCoords={setDrawArea} />)
  },[waypoints,drawArea])
  
  const layerStyle: CircleLayer = {
    source: 'my-data',
    id: 'point',
    type: 'circle',
    paint: {
      'circle-radius': 2,
      'circle-color': '#007cbf'
    }
  };

  const handleSearch = () => {
    // Search for the vessel in the list
    console.log("Finding : ",searchInput)
    const searchVesselName = searchInput.split("|")[0].trim()
    const searchMmsiNumber = searchInput.split("|")[1].trim()
    const searchCallSign = searchInput.split("|")[2].trim()
    //console.log(searchVesselName,searchCallSign,searchMmsiNumber)
    const mapbox = mapRef.current?.getMap();
    const result = allVesselPositions.find(
        (vessel) => vessel.vesselParticulars.vesselName.toLowerCase().includes(searchVesselName.toLowerCase()) ||
          vessel.vesselParticulars.mmsiNumber.includes(searchMmsiNumber)|| vessel.vesselParticulars.callSign.toLowerCase().includes(searchCallSign.toLowerCase())
      );
      //console.log(result)
      if (result) {
        setCurrVessel(result);
        setPopupInfo({
            vesselName: result.vesselParticulars.vesselName,
            callSign: result.vesselParticulars.callSign,
            mmsiNumber: result.vesselParticulars.mmsiNumber,
            longitudeDegrees: result.longitudeDegrees,
            latitudeDegrees: result.latitudeDegrees,
            speed: result.speed,
            heading: result.heading
        })
        // Pan and zoom the map to the vessel's location if map is available
        console.log("Checking current map instance: ",mapbox)
        if (mapbox) {
          mapbox.flyTo({
            center: [result.longitudeDegrees, result.latitudeDegrees],
            zoom: 14,
            essential: true,
          });
        }
      } else {
        alert("Vessel not found");
      }
  }
  const handleDraw = useCallback((event:any) => {
    if (!isDrawing) return;
    //console.log("Selected coordinates: ",event.lngLat)
    const a :Waypoint = {longitude: event.lngLat.lng,latitude:event.lngLat.lat}
    if(isDrawAreaSelector){
      //console.log("Setting Draw Area")
      setDrawArea((prev) =>{
        const updatedArea = [...prev, a];
          //console.log("Updated Draw Area Length:", updatedArea.length);
          //console.log("Updated Draw Area:", updatedArea);
          return updatedArea;});
      }
    if(isWaypointSelector){
      //console.log("Setting Waypoints")
      setWaypoints((prev) => {
        const updatedWaypoints = [...prev,a];
        return updatedWaypoints
      })
      }

    }
   
  , [isWaypointSelector,isDrawAreaSelector,isDrawing]);

  const handleExport = async () => {
    if(drawArea !== undefined && drawArea !== null && waypoints !== undefined && waypoints !== null)
      getKMZFile(user,drawArea,waypoints)
    .then((response) => {
      // Create a URL for the blob
      const blob = new Blob([response.data], { type:'application/zip' });
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'my_flight_path.kmz'); // Default file name
      document.body.appendChild(link);

      // Programmatically click the link to start download
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

    })
    .catch((err) => console.log(err))

  }
  // Close the polygon by connecting the last point to the first
  const closePolygon = () => {
    if (drawArea.length > 2) {
      setDrawArea((prev) => [...prev, prev[0]]);
      setIsDrawing(false);
    }
  }
  const clearPolygon = () =>{
    setDrawArea([])
  }
  const clearWaypoints = () => {
    setWaypoints([])
  }

  /*
      <Source id="arrow-source" type="geojson" data={arrowGeoJSON(allVesselPositions)}>
      <Layer {...layerStyle} />
    </Source>
*/
/*
      <input
        type="text"
        placeholder="Enter vessel name or MMSI number"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)} // Update input value
      />
      */
  
  const LayersControlPanel = (props: { ref: RefObject<MapRef> }) => {
      const {ref} = props
    return(
    <Box ref={ref} sx={{
      position: 'absolute',
      top: '150px',
      right: '10px',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      borderRadius: '4px',
      boxShadow: '0 1px 4px rgba(0, 0, 0, 0.2)',
      zIndex: 2}}>
      <DisplayShipLayer shipLayerVisible={shipLayerVisible} setShipLayerVisible={setShipLayerVisible} setIsDistance={setIsDistance} />
      <DisplayDroneLayer droneLayerVisible={droneLayerVisible} setDroneLayerVisible={setDroneLayerVisible} setIsDistance={setIsDistance}/>
      <DisplayDroneSafetyRadiusesLayer droneSafetyRadiusesVisible={droneSafetyRadiusesVisible} setDroneSafetyRadiusesVisible={setDroneSafetyRadiusesVisible} />
      <DrawPolygonLayer isDrawing={isDrawing} setIsDrawing={setIsDrawing} closePolygon={closePolygon} clearPolygon={clearPolygon} clearWaypoints={clearWaypoints}/>
    </Box>
    )}
  const DrawingControlPanel = () => {
    if(isDrawing){
    return(
      <Box sx={{display:"flex",flexDirection:"row",position:"absolute",top:"10px",right:"30px",justifyContent:'center'}}>
        <WaypointSelectorControl isWaypointSelector={isWaypointSelector} setIsWaypointSelector={setIsWaypointSelector} setIsDrawAreaSelector={setIsDrawAreaSelector} />
        <DrawAreaSelectorControl isDrawAreaSelector={isDrawAreaSelector} setIsWaypointSelector={setIsWaypointSelector} setIsDrawAreaSelector={setIsDrawAreaSelector} />
      </Box>
    )
    }
    else
      return (<></>)
  }

  return (
    <>
      <SideBar />
      <ToastContainer />
        <CssBaseline />
        <Box>
        <Map
        ref={mapRef}
      mapboxAccessToken={MAPBOX_API_KEY}
      initialViewState={{
        longitude: 103.808053,
        latitude: 1.351616,
        zoom: 9
      }}
      style={{width: "80vw", height: "80vh"}}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      onClick={handleDraw}
      onError={(err) => {console.log(err)}}
    >
      
        <FullscreenControl style={{outlineColor:'red',outlineStyle:'double'}}/>
        <NavigationControl style={{outlineColor:'red',outlineStyle:'double'}}/>
        
       <LayersControlPanel ref={mapRef} />
        <DrawingControlPanel/>
    <Box style={{display:"flex",flexDirection:"row",marginTop:10,marginLeft:2}}>
      <Box style={{flex:1}}>
          <Autocomplete
              disablePortal
              getOptionLabel={(option) => `${option.vesselName} | ${option.mmsiNumber} | ${option.callSign}`}
              options={allVesselPositions.length ? allVesselPositions.map((vesselPosition) => ({
                  vesselName: String(vesselPosition.vesselParticulars.vesselName),
                  mmsiNumber: String(vesselPosition.vesselParticulars.mmsiNumber),
                  callSign: String(vesselPosition.vesselParticulars.callSign),
              })) : []}
              sx={{ width: 500 }}
              renderInput={(params) => <TextField {...params} label="Vessel name / MMSI number / Call sign" />}
              onInputChange={(event,value) => setSearchInput(value)}
          
          />
          <Button 
              onClick={handleSearch}
              variant="contained"
              >Search
          </Button>
      </Box>
    </Box>

   {selectedArea}
    {shipLayerVisible && pins}
    {droneLayerVisible && drones}
    {droneSafetyRadiusesVisible && droneSafetyRadiuses}
    {!isDrawing && dronePopUpInfo && (
          <Popup
            anchor="top"
            longitude={Number(dronePopUpInfo.longitude)}
            latitude={Number(dronePopUpInfo.latitude)}
            onClose={() => {setDronePopUpInfo(null);setIsDistance(false)}}
          >
            <div>
              UASID: {dronePopUpInfo?.UASID}<br/>
              Heading: {dronePopUpInfo?.trackDirection} degrees<br/>
              Speed: {dronePopUpInfo?.speed}m/s<br/>
              Height: {dronePopUpInfo?.height}m<br/>
              TimeStamp: {dronePopUpInfo?.timestamp}<br/>

            </div>
          </Popup>
        )}
    {!isDrawing && popupInfo && (
          <Popup
            anchor="top"
            longitude={Number(popupInfo.longitudeDegrees)}
            latitude={Number(popupInfo.latitudeDegrees
            )}
            style={{opacity:1.0,borderRadius:0.2}}
            onClose={() => setPopupInfo(null)}
          >
            <div>
              Vessel Name: {popupInfo?.vesselName}<br/>
              CallSign: {popupInfo?.callSign}<br/> 
              mmsi Number: {popupInfo?.mmsiNumber}<br/>
              Speed: {popupInfo?.speed} <br/>
            </div>
            
            <Button
            onClick={() => {setIsDistance(true)}}
            >
              Distance from Drone              
            </Button>
          </Popup>
        )}
        <DronetoVesselDistancePlot 
          user={user}
          mapRef={mapRef}
          isDistance={isDistance}
          currDrone={currDrone}
          currVessel={currVessel} />
        <ControlPanel props={currVessel} />
      <Box sx={{position:'absolute',right:'10px',bottom: '20px',width:'auto',height:'auto'}}>
       {
       drawArea.length >= 3 && 
        <Button variant="contained" onClick={handleExport}>
          Export
        </Button>
        }
      </Box>
    </Map>

    </Box>
    </>
  );
      }

const LoadingPage =() => {
  return (
  <>
      <Box style={{display:"flex"}}>
    <SideBar/>
      <div style={{position:"absolute",top:"50%",left:"50%"}}>
      <CircularProgress />
      </div>
    </Box>
  </>)
}



export default AISDashboard;

